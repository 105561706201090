import React from 'react';

import Layout from '../../components/Layout';

export default function Sanitaetsdienst() {
	return (
		<Layout title="Sanitätsdienst">
			<h2>Sanitätsdienst</h2>
			<p>
				Sanitätsdienste werden für Sportveranstaltungen, Festivals oder sonstige Großveranstaltungen benötigt.
			</p>
			<p>Wir bieten:</p>
			<p>
				<ul>
					<li>Im Notfall professionelle Hilfe direkt Vorort</li>
					<li>Eine kostenlose Gefahrenanalyse für Ihre Veranstaltung</li>
					<li>Sanitätsdienstliche Betreuung</li>
				</ul>
			</p>
			<p>Sie benötigen einen Sanitätsdienst?</p>
			<p>Gerne lassen wir Ihnen ein (für Ihre Veranstaltung angepasstes) Angebot zukommen!</p>
		</Layout>
	);
}
